import React from 'react'
import { pure } from 'recompose'
import slug from 'slugify'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'

const slugify = text => slug(text, { lower: true })

const ReferenceTabs = ({ current, tabs = [], editMode }) => {
  const currentTab = current || (tabs[0] && slugify(tabs[0]))
  return (
    <AppBar position="static" color="default">
      <Tabs fullWidth centered value={currentTab} onChange={() => {}}>
        {tabs.map((item, idx) => (
          <Tab
            key={idx}
            label={item}
            href={`#${slugify(item)}`}
            value={slugify(item)}
            disabled={editMode}
          />
        ))}
      </Tabs>
    </AppBar>
  )
}

export default pure(ReferenceTabs)
