import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { connect } from 'react-redux'
import { reduxForm, formValueSelector, FieldArray } from 'redux-form'
import {
  Form,
  TextField,
  NumberField,
  DateField,
  SelectField,
  AutocompleteField
} from '@ftrustee/ui'

import { find } from 'lodash'

const FormTemplate = ({ options, onChange, ...props }) => {
  console.log('render#FormTemplate')
  return (
    <Form {...props}>
      <AutocompleteField
        required
        name="issuer.id"
        label="Emissor"
        xs={12}
        sm={6}
        options={options.companies}
      />
      <SelectField
        required
        sm={2}
        name="type"
        label="Instrumento"
        options={options.types}
        onChange={value => onChange(value.target)}
      />
      <NumberField
        required
        xs={4}
        sm={2}
        name="issueNumber"
        label="Número"
        helperText="emissão"
        onChange={value => onChange({ name: 'issueNumber', value })}
      />
      <NumberField
        required
        xs={4}
        sm={1}
        name="seriesStart"
        label="Inicial"
        helperText="série"
        onChange={value => onChange({ name: 'seriesStart', value })}
      />
      <NumberField
        required
        xs={4}
        sm={1}
        name="seriesEnd"
        label="Final"
        helperText="série"
        onChange={value => onChange({ name: 'seriesEnd', value })}
      />
      <TextField required xs={12} sm={7} name="name" label="Nome da Operação" />
      <TextField disabled sm={3} name="cod" label="Código" />
      <SelectField
        sm={2}
        name="status"
        label="Status"
        options={options.status}
      />
      <AutocompleteField
        isClearable={true}
        name="owner.id"
        label="Mandatário"
        sm={4}
        options={options.companies}
      />
      <AutocompleteField
        isClearable={true}
        name="manager.id"
        label="Coordenador"
        sm={4}
        options={options.companies}
      />
      <AutocompleteField
        isClearable={true}
        name="keeper.id"
        label="Escriturador"
        sm={4}
        options={options.companies}
      />
      <TextField multiline rowsMax="4" name="notes" label="Anotações" sm={12} />

      {/* <FieldArray name="series" component={renderSeries} /> */}
    </Form>
  )
}

const renderSeries = ({ fields, meta: { error, submitFailed } }) => (
  <React.Fragment>
    {fields.map((member, index) => {
      console.log(member)
      return (
        <React.Fragment key={index}>
          <TextField sm={2} name={`${member}.isin`} label="ISIN" />
          <TextField sm={2} name={`${member}.cetip`} label="CETIP" />
          <DateField sm={2} name={`${member}.issueDate`} label="Emissão" />
          <DateField sm={2} name={`${member}.dueDate`} label="Vencimento" />
          <NumberField sm={2} name={`${member}.value`} label="Valor Emitido" />
          <NumberField
            sm={2}
            name={`${member}.amount`}
            label="Quantidade Emitida"
          />
        </React.Fragment>
      )
    })}
  </React.Fragment>
)

FormTemplate.propTypes = {
  options: PropTypes.object.isRequired
}

FormTemplate.defaultProps = {
  options: {}
}

const FormView = compose(
  reduxForm({ form: 'bond' }),
  // withState('loading', 'isLoading', false),
  connect(
    (state, { autofill, options }) => {
      const selector = formValueSelector('bond')
      const { issuer, type, issueNumber, seriesStart, seriesEnd } = selector(
        state,
        'issuer',
        'type',
        'issueNumber',
        'seriesStart',
        'seriesEnd'
      )

      return {
        onChange: target => {
          console.log('onChange')
          if (!options) return

          const issuerCod = find(options.companies, { value: issuer.id }).cod
          let cod = ''
          switch (target.name) {
            case 'type':
              cod = `${target.value}${issuerCod}${String(issueNumber).padStart(
                3,
                '0'
              )}${String(seriesStart).padStart(4, '0')}${String(
                seriesEnd
              ).padStart(4, '0')}`
              break
            case 'issueNumber':
              cod = `${type}${issuerCod}${String(target.value).padStart(
                3,
                '0'
              )}${String(seriesStart).padStart(4, '0')}${String(
                seriesEnd
              ).padStart(4, '0')}`
              break
            case 'seriesStart':
              cod = `${type}${issuerCod}${String(issueNumber).padStart(
                3,
                '0'
              )}${String(target.value).padStart(4, '0')}${String(
                seriesEnd
              ).padStart(4, '0')}`
              break
            case 'seriesEnd':
              cod = `${type}${issuerCod}${String(issueNumber).padStart(
                3,
                '0'
              )}${String(seriesStart).padStart(4, '0')}${String(
                target.value
              ).padStart(4, '0')}`
              break

            default:
              break
          }
          console.log(target, cod)
          autofill('cod', cod)

          // props.updateValue(event.target.value)
        }
      }
    },
    (dispatch, ownProps) => ({
      isLoading: loading =>
        dispatch({
          type: 'APP_LOADING',
          payload: loading
        })
    })
  )
)(FormTemplate)

export default FormView
