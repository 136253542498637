import { compose, branch, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { omit, omitBy, remove, isNull, pick } from 'lodash'

import FormView from './FormView'
import { QUERY } from './GridContainer'

const fields = gql`
  fragment fields on Security {
    id
    cod
    name
    type
    status
    notes
    issuer {
      id
    }
    manager {
      id
    }
    keeper {
      id
    }
    owner {
      id
    }
  }
`

const withData = graphql(
  gql`
    query security($id: ID!) {
      security(id: $id) {
        ...fields
      }
    }
    ${fields}
  `,
  {
    options: ({ match }) => ({
      variables: {
        id: match.params.id
      }
    }),
    props: ({ data }) => {
      const props = {
        loading: data.loading
      }
      if (!data.loading) {
        const initialValues = omit(omitBy(data.security, isNull), '__typename')

        initialValues.issueNumber = parseInt(initialValues.cod.substr(7, 3))
        initialValues.seriesStart = parseInt(initialValues.cod.substr(10, 4))
        initialValues.seriesEnd = parseInt(initialValues.cod.substr(14, 4))
        props.initialValues = initialValues
      }
      return props
    }
  }
)

const withSave = graphql(
  gql`
    mutation createSecurity($data: SecurityInput!) {
      createSecurity(data: $data) {
        ...fields
      }
    }
    ${fields}
  `,
  {
    props: ({ mutate, ownProps: { history } }) => ({
      onSubmit: values => {
        const data = pick(
          values,
          'cod',
          'name',
          'type',
          'status',
          'notes',
          'issuer.id',
          'manager.id',
          'keeper.id',
          'owner.id'
        )

        return mutate({ variables: { data } }).then(() =>
          history.push('/securities')
        )
      }
    }),
    options: () => ({
      update: (cache, { data: { createSecurity } }) => {
        const { securities } = cache.readQuery({ query: QUERY })
        cache.writeQuery({
          query: QUERY,
          data: { securities: securities.concat([createSecurity]) }
        })
      }
    })
  }
)

const withUpade = graphql(
  gql`
    mutation updateSecurity($id: ID!, $data: SecurityInput!) {
      updateSecurity(id: $id, data: $data) {
        ...fields
      }
    }
    ${fields}
  `,
  {
    props: ({ mutate, ownProps: { history } }) => ({
      onSubmit: values => {
        console.log(values)
        const { id } = values
        const data = pick(
          values,
          'cod',
          'name',
          'type',
          'status',
          'notes',
          'issuer.id',
          'manager.id',
          'keeper.id',
          'owner.id'
        )
        return mutate({ variables: { id, data } }).then(() =>
          history.push('/securities')
        )
      }
    })
  }
)

const withDelete = graphql(
  gql`
    mutation deleteSecurity($id: ID!) {
      deleteSecurity(id: $id) {
        id
      }
    }
  `,
  {
    props: ({ mutate, ownProps: { match, history } }) => ({
      onDelete: () =>
        mutate({ variables: { id: match.params.id } }).then(() =>
          history.replace('/securities')
        )
    }),
    options: ({ match }) => ({
      update: (proxy, { data: { deleteSecurity } }) => {
        const data = proxy.readQuery({
          query: QUERY,
          variables: {
            id: match.params.id
          }
        })
        remove(data.securities, deleteSecurity)
        proxy.writeQuery({
          query: QUERY,
          variables: {
            id: match.params.id
          },
          data
        })
      }
    })
  }
)

const withCompanies = graphql(
  gql`
    query {
      companies {
        id
        name
        cod
      }
    }
  `,
  {
    props: ({ data }) =>
      !data.loading && {
        options: {
          types,
          status,
          companies: data.companies.map(company => ({
            value: company.id,
            label: company.name,
            cod: company.cod
          }))
        }
      }
  }
)

export default compose(
  withCompanies,
  branch(
    ({ match }) => !!match.params.id,
    compose(
      withData,
      withUpade,
      withDelete
    ),
    withSave
  ),
  withHandlers({
    onCancel: ({ history }) => () => history.push('/securities')
  })
)(FormView)

const types = [
  { value: 'CCB', label: 'CCB' },
  { value: 'CCE', label: 'CCE' },
  { value: 'CCI', label: 'CCI' },
  { value: 'CDC', label: 'CDC' },
  { value: 'CPR', label: 'CPR' },
  { value: 'CRA', label: 'CRA' },
  { value: 'CRI', label: 'CRI' },
  { value: 'DEB', label: 'DEB' },
  { value: 'LCI', label: 'LCI' },
  { value: 'LFB', label: 'LFB' },
  { value: 'LFS', label: 'LFS' },
  { value: 'NCE', label: 'NCE' },
  { value: 'NPC', label: 'NPC' },
  { value: 'OPI', label: 'OPI' }
]

const status = [
  {
    value: 'default',
    label: 'Default'
  },
  {
    value: 'active',
    label: 'Ativa'
  },
  {
    value: 'deleted',
    label: 'Excluída'
  }
]
